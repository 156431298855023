@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

.otherTitle {
  font-family: "Raleway", sans-serif;
  color: white;
  font-size: 4.7rem;
  font-weight: 700;
  cursor: default;
  padding-top: 0.4em;
}

.aboutTitle {
  font-family: "Raleway", sans-serif;
  color: white;
  font-size: 4rem;
  font-weight: 700;
  cursor: pointer;
}

.otherTitleMobile {
  font-family: "Raleway", sans-serif;
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
  cursor: default;
  text-align: center;
}

.aboutTitleMobile {
  font-family: "Raleway", sans-serif;
  color: white;
  font-size: 3rem;
  padding-top: 0.2em;
  font-weight: 600;
  cursor: pointer;
}

.aboutTitle:hover {
  cursor: "pointer";
  color: orange;
  margin-left: 0.2em;
}

.aboutTitleMobile:hover {
  cursor: "pointer";
  color: orange;
}

.navButton {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
}

@media only screen and (max-width: 1516px) {
  .test {
    display: none;
  }
}
