@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

.catalogueTitle {
  font-family: "Raleway", sans-serif;
  color: white;
  font-size: 4.2rem;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.catalogueTitle:hover {
  cursor: "pointer";
  color: orange;
  margin-left: 0.2em;
}

.catalogueTitleMobile {
  font-family: "Raleway", sans-serif;
  color: white;
  font-size: 3.5rem;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.catalogueTitleMobile:hover {
  cursor: "pointer";
  color: orange;
  margin-left: 0.2em;
}
