@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

.newsTitle {
  font-family: "Raleway", sans-serif;
  color: white;
  font-size: 4.2rem;
  font-weight: 500;
  margin-top: 0;
  cursor: default;
}

.newsInfo {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 0em;
  cursor: default;
}

.newsHero {
  background-color: #0b0b0b;
}
