@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");
.songName {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 2.5rem;
  font-weight: 600;
}

.songNameMobile {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 2rem;
  font-weight: 600;
}

.buttonText {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 0.3em;
}

.info {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 1rem;
  font-weight: 600;
}
