@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

.projectsTitle {
  font-family: "Raleway", sans-serif;
  color: white;
  font-size: 4.5rem;
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0.5em;
  cursor: default;
}
